.p-relative {
  position: relative;
}

.p-fixed {
  position: fixed;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.nowrap {
  white-space: nowrap;
}

.d-none {
  display: none;
}

.d-block {
  display: block;
}

.d-inline {
  display: inline;
}

.d-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.flex-column-reverse {
  flex-direction: column-reverse;
}

.flex-row {
  flex-direction: row;
}

.flex-wrap {
  flex-wrap: wrap;
}

.align-items-center {
  align-items: center;
}

.align-items-end {
  align-items: flex-end;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-baseline {
  align-items: baseline;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.flex-1 {
  flex: 1;
}

.text-align-center {
  text-align: center;
}

.w-100 {
  width: 100%;
}

.w-50 {
  width: 50%;
}

.h-100 {
  height: 100%;
}

.text-through {
  text-decoration: line-through;
}

.text-underline {
  text-decoration: underline;
}

.text-align-end {
  text-align: end;
}

.text-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.noscroll {
  overflow: hidden;
}

@media #{$screen-md} {
  .d-md-none {
    display: none;
  }

  .d-md-block {
    display: block;
  }

  .d-md-inline {
    display: inline;
  }

  .d-md-flex {
    display: flex;
  }

  .flex-md-row {
    flex-direction: row;
  }

  .flex-md-column {
    flex-direction: column;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse;
  }

  .flex-md-wrap {
    flex-wrap: wrap;
  }

  .align-items-md-center {
    align-items: center;
  }

  .align-items-md-end {
    align-items: flex-end;
  }

  .justify-content-md-between {
    justify-content: space-between;
  }

  .justify-content-md-center {
    justify-content: center;
  }

  .text-align-md-end {
    text-align: end;
  }

  .w-md-unset {
    width: unset;
  }
}
